import React, { useState, useEffect } from 'react';
import '../../styles/style.scss';

import Layout from '../../components/layout';

export default () => {
  const [currentTab, setCurrentTab] = useState('our-story');
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = { currentTab, setCurrentTab, licensedInOpened, setLicensedInOpened, getAQuoteOpened, setGetAQuoteOpened };

  const hasClass = function (el, className) {
    if (el.classList) return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  };

  const addClass = function (el, className) {
    if (!el) return;
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
  };

  const removeClass = (el, className) => {
    if (!el) return;

    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  };

  let supportPageOffset;

  let isCSS1Compat;

  try {
    supportPageOffset = window.pageXOffset;
    isCSS1Compat = ((document && document.compatMode) || '') === 'CSS1Compat';
  } catch (e) {}

  useEffect(() => {
    const buttons = document.getElementById('about-buttons');
    const content = document.getElementById('our-story');

    window.onscroll = () => {
      if (typeof window !== undefined) {
        const top = supportPageOffset ? window && window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        const offsetTop = 72;
        const sectionEnd = document.getElementById('about-end')?.offsetTop - 216;

        if (top >= offsetTop && top < sectionEnd) {
          addClass(buttons, 'about-buttons-fixed');
          addClass(content, 'about-y-down');
        } else {
          removeClass(buttons, 'about-buttons-fixed');
          removeClass(content, 'about-y-down');
        }

        const currentSections = ['our-story', 'leadership', 'our-team'];

        let currentSection;

        for (let s of currentSections) {
          if (!currentSection) {
            const ele = document.getElementById(s);
            if (ele) {
              const sTop = ele.offsetTop;
              if (top <= sTop) {
                currentSection = s;
              }
            }
          }
        }
        if (!currentSection) {
          try {
            currentSection = currentTab.toLowerCase();
          } catch (e) {}
        }

        for (let s of currentSections) {
          if (s == currentSection) {
            setCurrentTab(s);
          }
        }
      }
    };
  });

  return (
    <Layout activePage='about' {...props} id='about'>
      <div className='about-container'>
        <div className='hidden-on-mobile'>
          <div id='about-buttons' className={`about-buttons-container`}>
            <div className='plan-types-buttons-container-inner'>
              <div
                onClick={() => {
                  props.setCurrentTab && props.setCurrentTab('our-story');
                  const content = document.getElementById('our-story');
                  content.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`plan-types-insurance-button ${props.currentTab === 'our-story' ? 'plan-types-insurance-button-active' : 'false'}`}>
                <div className='plan-types-insurance-button-img'>
                  <svg>
                    <use xlinkHref='#our-story-icon' />
                  </svg>
                </div>
                <div className='plan-types-insurance-button-text'>
                  <div className='plan-types-insurance-button-text-bold'>OUR STORY</div>
                </div>
              </div>
              <div
                onClick={() => {
                  props.setCurrentTab && props.setCurrentTab('leadership');
                  const content = document.getElementById('leadership');
                  content.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`plan-types-insurance-button ${props.currentTab == 'leadership' ? 'plan-types-insurance-button-active' : ''}`}>
                <div className='plan-types-insurance-button-img'>
                  <svg>
                    <use xlinkHref='#leadership-icon' />
                  </svg>
                </div>
                <div className='plan-types-insurance-button-text'>
                  <div className='plan-types-insurance-button-text-bold'>LEADERSHIP</div>
                </div>
              </div>

              <div
                onClick={() => {
                  props.setCurrentTab && props.setCurrentTab('our-team');
                  const content = document.getElementById('our-team');
                  content.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`plan-types-insurance-button ${props.currentTab == 'our-team' ? 'plan-types-insurance-button-active' : ''}`}>
                <div className='plan-types-insurance-button-img'>
                  <svg>
                    <use xlinkHref='#our-team-icon' />
                  </svg>
                </div>
                <div className='plan-types-insurance-button-text'>
                  <div className='plan-types-insurance-button-text-bold'>OUR TEAM</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='what-makes-different' className='anchor' />
        <div id='our-story' className='what-makes-different-container'>
          <div className='what-makes-different'>WHAT MAKES APOLLO DIFFERENT?</div>

          <div className='what-makes-different-text'>
            We help our clients secure the best possible coverage at an affordable price. We offer a variety of plans suited for a variety of budgets and
            backgrounds. Apollo has long-standing, premier relationships with almost all major carriers and our services come with zero cost to you. We want to
            make your health insurance headaches our headache, not yours.
            <br />
            <br />
            With a combined 20+ years of experience, we are your industry experts!
          </div>
        </div>

        <div className='about-our-story-container'>
          <div className='about-banner' />

          <div className='about-our-story'>OUR STORY</div>

          <div className='about-our-story-sections'>
            <div className='about-our-story-section'>
              <div className='about-our-story-section-title'>APOLLO IN 2010</div>
              <div className='about-our-story-section-facts'>
                1 employee
                <br />
                25 carriers
                <br />
                200 clients
                <br />
                250 sq ft office space
                <br />
                (section design in progress)
                <br />
                <br />
              </div>
              <div className='about-our-story-section-text'>
                Our mission to Positively Impact People’s Lives was born - we set out to serve our clients by meeting their needs in assisting them to find the
                best possible healthcare package for their budget. We knew the struggles so many face with health insurance and how frustrating it can be. Our
                team knew we had to battle for our clients and act as advocates for our clients and not only learn about the industry, but master the
                intricacies of every plan available.
              </div>
            </div>
            <div className='about-our-story-section'>
              <div className='about-our-story-section-title'>APOLLO TODAY</div>
              <div className='about-our-story-section-facts'>
                100+ employees
                <br />
                100+ carriers
                <br />
                32,000+ clients
                <br />
                10,000 sq ft - 3 offices <br />
                (section design in progress)
                <br />
                <br />
              </div>
              <div className='about-our-story-section-text'>
                Since 2010, Apollo has blazed a path through the thick of this volatile industry by providing our clients with custom built coverage. Our
                clients value what we do for them, and often refer us to their friends and family. To Apollo, that says our clients trust us and know we truly
                care about their healthcare needs. We didn't want to be another healthcare brokerage, we wanted to show our clients we are their eyes and ears
                in this turbulent and ever-changing industry. We pride ourselves on ensuring we help keep our clients money in their pocket instead of giving it
                to the insurance company. At Apollo, we treat all of our clients just like family.
              </div>
            </div>
          </div>
        </div>
        <div className='anchor' id='leadership' />

        <div className='about-leadership-container'>
          <div className='about-leadership-title'>LEADERSHIP</div>
          <div className='about-leadership-subtitle'>Meet the Team That Makes It Happen</div>

          <div className='about-leadership-cards'>
            <div className='about-leadership-card scott'>
              <div className='about-leadership-card-title'>Scott Eckley</div>
              <div className='about-leadership-position'>President</div>
            </div>
            <div className='about-leadership-card dillon'>
              <div className='about-leadership-card-title'>Dillon Roher</div>
              <div className='about-leadership-position'>Chief Technical Officer</div>
            </div>
            <div className='about-leadership-card jordan'>
              <div className='about-leadership-card-title'>Jordan Eckley</div>
              <div className='about-leadership-position'>Vice President & Co-Owner</div>
            </div>
            <div className='about-leadership-card luke'>
              <div className='about-leadership-card-title'>Luke Eckley</div>
              <div className='about-leadership-position'>Vice President & Co-Owner</div>
            </div>
          </div>
        </div>
        <div className='anchor' id='our-team' />
        <div className='about-junior-partners-container'>
          <div className='about-junior-partners-title'>JUNIOR PARTNERS</div>

          <div className='about-junior-partners-cards'>
            <div className='about-junior-partner-card rich'>
              <div className='about-junior-partner-card-title'>Rich Robbertacio</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
            <div className='about-junior-partner-card phill'>
              <div className='about-junior-partner-card-title'>Phil Kathol</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
            <div className='about-junior-partner-card matt'>
              <div className='about-junior-partner-card-title'>Jeff </div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
            <div className='about-junior-partner-card peebles'>
              <div className='about-junior-partner-card-title'>Matt Peebles</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
          </div>
          <div className='about-junior-partners-cards'>
            <div className='about-junior-partner-card jared'>
              <div className='about-junior-partner-card-title'>Jared Boyett</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
            <div className='about-junior-partner-card marq'>
              <div className='about-junior-partner-card-title'>Marq Hughes</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
            <div className='about-junior-partner-card matt'>
              <div className='about-junior-partner-card-title'>Matt Safranek</div>
              <div className='about-junior-partner-card-posistion'>Junior Partner</div>
            </div>
          </div>
        </div>
      </div>
      <div className='anchor' id='about-end' />
    </Layout>
  );
};
